import { graphql, PageProps } from 'gatsby';
import StudentStoriesSection from '../components/home/StudentStoriesSection';
import SEO from '../components/Seo';
import Subscribe from '../components/Subscribe';
import { PostEntryProps } from '../types';

interface DataProps {
  stories?: {
    nodes?: PostEntryProps[];
  };
}

export function Head({ location }: { location: { pathname: string } }) {
  return (
    <SEO
      title="Student Stories"
      pathname={location.pathname}
      description="Meet some Christian college students. We think you’ll find these stories as inspiring as we do."
    />
  );
}

export default function VideosPage({ data, location }: PageProps<DataProps>) {
  // const searchQuery = new URLSearchParams(location.search).get('search');

  return (
    <main className="page-videos">
      <StudentStoriesSection
        className="section-m-t"
        layout="page"
        stories={data.stories?.nodes}
        // searchQuery={searchQuery || ''}
      />
      <Subscribe className="section-m-b" />
    </main>
  );
}

export const query = graphql`
  query {
    stories: allWpStory {
      nodes {
        ...StoryEntry
      }
    }
  }
`;
